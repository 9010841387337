import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Les animations en CSS",
  "description": "Explication, tutoriel à l’appui, des principes de base des animations en CSS3.",
  "date": "2012-12-21T00:00:00.000Z",
  "path": "/articles/les-animations-en-css/",
  "archive": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Pendant longtemps, les animations d’un site ont été faites en flash ou via des gifs animés, mais tout cela est en passe d’être remplacé grâce à deux nouveaux modules introduits par CSS3, animation et transition. Dans cet article je me concentrerai sur le premier pour vous montrer comment, grâce à une syntaxe claire est simple, il est dorénavant facile de mettre un peu de mouvement sur vos pages…`}</p>
    <h2>{`Le principe`}</h2>
    <p>{`La déclaration de l’animation dans le CSS se fait en 2 étapes :`}</p>
    <ul>
      <li parentName="ul">{`On définit au minimum les choses suivantes : le nom que l’on souhaite donner à notre animation (`}<code parentName="li" {...{
          "className": "text"
        }}>{`animation-name`}</code>{`), la durée de l’animation (`}<code parentName="li" {...{
          "className": "text"
        }}>{`animation-duration`}</code>{`) et le nombre de boucles (`}<code parentName="li" {...{
          "className": "text"
        }}>{`animation-iteration-count`}</code>{`). `}<a parentName="li" {...{
          "href": "https://developer.mozilla.org/en-US/docs/CSS/Using_CSS_animations"
        }}>{`D’autres propriétés`}</a>{` sont également disponibles, mais nous allons commencer simplement…`}</li>
      <li parentName="ul">{`On crée une règle `}<code parentName="li" {...{
          "className": "text"
        }}>{`@keyframes`}</code>{` identifiée par le nom de notre animation, et au sein de laquelle on précisera les changements que l’on souhaite appliquer, ainsi que le moment on souhaite les appliquer.`}</li>
    </ul>
    <h2>{`Syntaxe et valeurs de @keyframes`}</h2>
    <p>{`La syntaxe est la suivante :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` <identifiant>`}<span parentName="span" {...{
              "className": "token punctuation"
            }}>{`;`}</span></span>{`  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  [ [ from | to | <pourcentage>`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` ] [`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` from | to | <pourcentage&>`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` ]* bloc ]*
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Regardons ça en détails :`}</p>
    <ul>
      <li parentName="ul">{`<`}{`identifiant`}{`>`}{` : le nom que vous avez donné à votre animation`}</li>
      <li parentName="ul">{`from : le point de départ, équivalent à 0%`}</li>
      <li parentName="ul">{`to : le point d’arrivée, équivalent à 100%`}</li>
      <li parentName="ul">{`<`}{`pourcentage`}{`>`}{` : pourcentage du temps de l’animation auquel la keyframe commence`}</li>
    </ul>
    <h2>{`Un exemple`}</h2>
    <p>{`Puisque rien n’est plus parlant qu’un exemple concret, voyons donc un cas pratique. Mettons que nous ayons un logo intitulé "BigBlue", et que l’on souhaite pour une raison quelconque en animer la deuxième partie, "Blue", afin de lui ajouter un effet flottant et un changement de couleur.`}</p>
    <p className="info">
  Les <code>@keyframes</code> ne sont pour l’instant supportées que par certains
  navigateurs : Chrome (et Android), Firefox 5.0+, Opera 12+, Safari 4.0+ et IE
  10+ (<a href="https://developer.mozilla.org/fr/docs/CSS/@keyframes">source</a>
  ). Pour profiter de ce tutoriel, assurez-vous d’utiliser un de ces navigateurs
  :-)
    </p>
    <p>{`On cible d’abord en HTML l’élément que l’on souhaite animer :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "html"
    }}><pre parentName="div" {...{
        "className": "html"
      }}><code parentName="pre" {...{
          "className": "html"
        }}><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`h1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Big`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`<`}</span>{`span`}</span>{` `}<span parentName="span" {...{
              "className": "token attr-name"
            }}>{`class`}</span><span parentName="span" {...{
              "className": "token attr-value"
            }}><span parentName="span" {...{
                "className": "token punctuation attr-equals"
              }}>{`=`}</span><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span>{`blue`}<span parentName="span" {...{
                "className": "token punctuation"
              }}>{`"`}</span></span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{`Blue`}<span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`span`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token tag"
          }}><span parentName="span" {...{
              "className": "token tag"
            }}><span parentName="span" {...{
                "className": "token punctuation"
              }}>{`</`}</span>{`h1`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span></code></pre></div>
    <p>{`On définit ensuite dans notre CSS les valeurs d’animation: nom de l’animation, durée et boucle souhaitée (de 1, valeur par défaut, à infinite). Ici j’ai regroupé ensemble les 3 propriétés sous la propriété `}<code parentName="p" {...{
        "className": "text"
      }}>{`animation`}</code>{`.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`.blue`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`display`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` inline-block`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`/* nécessaire dans cet exemple pour l’animation mouvement */`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`animation`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` bluemove 3s infinite`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Puis on définit notre `}<code parentName="p" {...{
        "className": "text"
      }}>{`@keyframes`}</code>{`, avec l’état de départ, l’état que l’on souhaite à la moitié de l’animation et l’état de fin (identique à l’état de départ afin de boucler la boucle proprement) :`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "css"
    }}><pre parentName="div" {...{
        "className": "css"
      }}><code parentName="pre" {...{
          "className": "css"
        }}><span parentName="code" {...{
            "className": "token atrule"
          }}><span parentName="span" {...{
              "className": "token rule"
            }}>{`@keyframes`}</span>{` bluemove`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`translateY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`-5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` black`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`50%`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`translateY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` #1785bf`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
  `}<span parentName="code" {...{
            "className": "token selector"
          }}>{`to`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`transform`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`translateY`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`-5px`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
    `}<span parentName="code" {...{
            "className": "token property"
          }}>{`color`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`:`}</span>{` black`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
  `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`Attention, dans cet exemple je n’ai volontairement rien préfixé, mais pensez bien à rajouter les préfixes nécessaires de votre côté pour que cela fonctionne (`}<code parentName="p" {...{
        "className": "text"
      }}>{`@-webkit-keyframes`}</code>{`, `}<code parentName="p" {...{
        "className": "text"
      }}>{`@-moz-keyframes`}</code>{` etc).`}</p>
    <h2>{`En conclusion`}</h2>
    <p>{`Les animations en CSS sont promises à un bel avenir. Elles sont déjà globalement bien supportées (à part quelques mauvais élèves, dont IE9-) et permettent d’obtenir des effets parfois vraiment sympathiques. Attention en revanche à ne pas en user et abuser au risque de perturber la navigation de vos visiteurs !`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      